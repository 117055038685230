var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "plugDrawer", class: "plug-drawer plug-drawer-" + _vm.position },
    [
      _c("div", {
        staticClass: "plug-drawer-mask",
        on: { click: _vm.toggleDrawer }
      }),
      _c(
        "div",
        {
          staticClass: "plug-drawer-content-wrapper",
          style: { width: _vm.oWidth, height: _vm.oHeight }
        },
        [
          _c("div", { staticClass: "plug-drawer-content" }, [
            _c(
              "div",
              { staticClass: "plug-drawer-body" },
              [_vm._t("default")],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }