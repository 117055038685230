/* eslint-disable */
function broadcast(componentName, eventName, params) {
  this.$children.forEach(child => {
    var _name = child.$options.name;
    var name = child.$options.componentName;
    if (!name) {
      try {
        name = child.$el.attributes && child.$el.attributes["name"] && child.$el.attributes.name.value;
      } catch (e) {
        console.log(e.messsage);
      }
    }

    if (name === componentName) {
      child.$emit.apply(child, [eventName].concat(params));
    } else {
      broadcast.apply(child, [componentName, eventName].concat([params]));
    }
  });
}

// 查找组件(根据组件名称)
function findComponent(componentName) {
  var existChild = null;
  this.$children.forEach(child => {
    var name = child.$options.name || child.$options.componentName;
    if (!name) {
      try {
        name = child.$el.attributes && child.$el.attributes["name"] && child.$el.attributes.name.value;
      } catch (e) {
        console.log(e.messsage);
      }
    }

    if (name === componentName) {
      existChild = child;
      return false;
    } else {
      var _subExistsChild = findComponent.apply(child, [componentName]);
      if (_subExistsChild) {
        existChild = _subExistsChild;
      }
    }
  });

  return existChild;
}

// 根据路由查找缓冲组件
function findKeepComponent(routeName) {
  var existChild = null;
  this.$children.forEach(child => {
    var key = child.$vnode.key;
    if (key && key.indexOf(routeName) >= 0) {
      existChild = child;
      return false;
    } else {
      var _subExistsChild = findKeepComponent.apply(child, [routeName]);
      if (_subExistsChild) {
        existChild = _subExistsChild;
      }
    }
  });
  return existChild;
}

// 查找组件(根据ref名)
function findRef(refName) {
  var existChild = this.$refs[refName];
  if (existChild) return existChild;

  this.$children.forEach(child => {
    var _subExistsChild = findRef.apply(child, [refName]);
    if (_subExistsChild) {
      existChild = _subExistsChild;
      return;
    }
  });

  return existChild;
}

export default {
  methods: {
    dispatch(componentName, eventName, params) {
      var parent = this.$parent || this.$root;
      var name = parent.$options.componentName;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;

        if (parent) {
          name = parent.$options.componentName;
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    },
    broadcast(componentName, eventName, params) {
      broadcast.call(this, componentName, eventName, params);
    },
    findComponent(componentName) {
      var root = this.$root;
      return findComponent.call(root, componentName);
    },
    findKeepComponent(routeName) {
      var root = this.$root;
      return findKeepComponent.call(root, routeName);
    },
    findRef(refName) {
      var root = this.$root;
      return findRef.call(root, refName);
    },
    findChildRef(refName) {
      var root = this.$parent;
      return findRef.call(root, refName);
    }
  }
};
