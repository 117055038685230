const create_check = {
  state: {
    ids: [],
    consignor_Id: null,
    storage_Id: null,
    index: 0,
    total: 0
  },
  mutations: {
    removeAllId(state) {
      state.ids = [];
    },
    addCreateCheckId(state, id) {
      state.ids.push(id);
    },
    resetIds(state, ids) {
      state.ids = ids;
    },
    setCreateCheckConsingnor_Id(state, consignor_Id) {
      state.consignor_Id = consignor_Id;
    },
    setCreateCheckStorage_Id(state, storage_Id) {
      state.storage_Id = storage_Id;
    },
    setCreateCheckIndex(state, index) {
      state.index = index;
    },
    addCreateCheckIndex(state) { // 自增
      state.index = state.index + 1;
    },
    setCreateCheckTotal(state, total) {
      state.total = total;
    }
  },
  actions: {

  }
};
export default create_check;
