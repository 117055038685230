var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-nav" }, [
    _c("div", { staticClass: "sys-name" }, [
      _vm._v(" " + _vm._s(_vm.$tt(_vm.basicInfo.sysName)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }