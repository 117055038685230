var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "window-tips-box"
    },
    [
      _c(
        "div",
        {
          staticClass: "el-alert is-center is-dark",
          class:
            ((_obj = {}),
            (_obj["el-alert--" + _vm.tipsConfig.type] = true),
            _obj)
        },
        [
          _c("i", {
            staticClass: "el-alert__icon",
            class:
              ((_obj$1 = {}),
              (_obj$1["el-icon-" + _vm.tipsConfig.type] = true),
              _obj$1)
          }),
          _c("div", { staticClass: "el-alert__content" }, [
            _c("span", {
              staticClass: "el-alert__title",
              domProps: { innerHTML: _vm._s(_vm.tipsConfig.message) }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }