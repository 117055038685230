<template>
  <div class="navbar">
    <div class="left-native">
      <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />
      <!-- 子应用列表 -->
      <top-nav :basic-info="basicInfo" />
    </div>

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <error-log v-if="NODE_ENV==='development'" class="errLog-container right-menu-item" />

        <el-tooltip :content="$t('navbar.screenfull')" class="right-menu-item" effect="dark" placement="bottom">
          <screenfull class="screenfull right-menu-item" />
        </el-tooltip>

        <!-- <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select class="right-menu-item"/>
        </el-tooltip> -->

        <lang-select class="right-menu-item" />

        <!-- <el-tooltip :content="$t('navbar.theme')" effect="dark" placement="bottom">
          <theme-picker class="theme-switch right-menu-item"/>
        </el-tooltip> -->
      </template>

      <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <div class="avatar-wrapper">
          <img :src="basicInfo.icon" class="user-avatar">
          <span class="user-true-name">{{ $tt(common.getUserInfo().userTrueName) }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/">
            <el-dropdown-item>
              <i class="el-icon-yrt-shouye1" style="margin-right: 5px;" />
              <span>{{ $tt('首页') }}</span>
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="themeSetting">
            <svg-icon icon-class="themeSetting" style="margin-right: 5px;" />
            <span>{{ $tt('主题设置') }}</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <i class="el-icon-yrt-icon21" style="margin-right: 5px;" />
            <span @click="modifypwd">{{ $tt('修改密码') }}</span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <i class="el-icon-yrt-tuihuochuli" style="margin-right: 5px;" />
            <span @click="logout">{{ $tt('退出系统') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!--修改密码弹出页面-->
    <modify-pwd ref="modifypwd-dialog" :visible.sync="modifyPwdVisible" is-orgin-pwd></modify-pwd>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import ErrorLog from "@/components/ErrorLog";
import Screenfull from "./Screenfull";
import LangSelect from "@/components/LangSelect";
import ModifyPwd from "@/views/sys/permission/components/modify-pwd";

export default {
  components: {
    TopNav,
    Hamburger,
    ErrorLog,
    Screenfull,
    LangSelect,
    ModifyPwd
  },
  data() {
    return {
      // 系统信息
      basicInfo: {},
      // 修改密码页面默认不显示
      modifyPwdVisible: false,
      NODE_ENV: process.env.NODE_ENV,
      userInfo: {},
      // userCheckHandler
      userCheckHandler: null
    };
  },
  computed: {
    ...mapGetters(["sidebar", "name", "avatar", "device"]),
    language() {
      return this.$store.getters.language;
    }
  },
  created() {
    this.getBasicInfo();
    this.userInfo = this.common.getUserInfo();
    // 首次登陆必须修改密码
    if (this.userInfo.isFirstLogin || this.userInfo.isPwdDueSoon) {
      this.modifyPwdVisible = true;
    }
  },
  mounted() {
    this.userCheckHandler = setInterval(() => {
      this.userCheck();
    }, 10 * 1000);
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    logout() {
      window.clearInterval(this.userCheckHandler);
      this.userCheckHandler = null;

      const url = "/api/auth/logout";
      const params = {};
      this.common.ajax(url, params, res => {
        this.common.showMsg(res);
        this.$store.dispatch("LogOut").then(() => {
          var storageKey = "$tokenInfo" + this.$store.getters.userType;
          localStorage.removeItem(storageKey);
          location.reload();
        });
      }, true);
    },
    // 获得系统信息
    getBasicInfo() {
      const href = window.location.href;
      const url = "/api/sys/basicInfo/getInfo";
      this.common.ajax(
        url,
        {
          href: href,
          lang: this.language
        },
        res => {
          // 返回错误的时候停止运行，避免basicInfo变成null
          // 现在的情况是第一次请求获得了正确的参数，后面有两次多与的错误请求。
          if (!res.data) {
            return;
          }

          this.basicInfo = res.data;
          this.$store.dispatch("setBasicInfo", this.basicInfo);
          this.common.basicInfo = res.data; // 记录权限
          document.title = this.basicInfo.sysName;
          let $favicon = document.querySelector('link[rel="icon"]');
          const link = this.basicInfo.icon;
          if ($favicon !== null) {
            $favicon.href = link;
          } else {
            $favicon = document.createElement("link");
            $favicon.rel = "icon";
            $favicon.href = link;
            document.head.appendChild($favicon);
          }

          // 加载语言包
          if (Array.isArray(res.data2)) {
            for (const item of res.data2) {
              this.$mergeLang(item.lang, JSON.parse(item.langPackage));
            }
          }
        }
      );
    },
    modifypwd() {
      this.modifyPwdVisible = true;
    },
    themeSetting() {
      this.$store.dispatch("toggleDrawer");
    },
    // 用户心跳
    userCheck() {
      const userInfo = this.common.getUserInfo();
      if (userInfo.global_strongPasswordPolicy) {
        const guid = this.common.getUserGuid();
        const url = "/api/sys/basicInfo/userCheck";
        const params = {
          guid: guid
        };
        this.common.ajax(url, params, res => {
          // this.common.showMsg(res);
        });
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #f2f3f7;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  .hamburger-container {
    flex-shrink: 0;
    padding: 3px 10px 0;
  }
  .left-native {
    display: flex;
    height: 64px;
    line-height: 64px;
    flex: 1;
    overflow: hidden;
  }
  .right-menu {
    float: right;
    height: 100%;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
      height: 50px;
      vertical-align: text-top;
      padding-top: 15px;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      height: 30px;
      margin-right: 10px;
      .avatar-wrapper {
        cursor: pointer;
        position: relative;
        .user-avatar {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          vertical-align: middle;
          margin-right: 5px;
        }
        .el-icon-caret-bottom {
          position: absolute;
          right: -15px;
          top: 5px;
          font-size: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .act-color {
    display: none !important;
  }
  .campus-names {
    display: none !important;
  }
  .user-name {
    padding-top: 0 !important;
    line-height: 46px !important;
  }
}
</style>
