var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _vm._v(" basicInfo=" + _vm._s(_vm.basicInfo) + " "),
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("div", [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.basicInfo.icon }
              })
            ]),
            _c("div", [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$tt(_vm.basicInfo.corpShortName) || "仓配一体化")
                )
              ]),
              _vm.language != "en"
                ? _c("p", [_vm._v(_vm._s(_vm.$tt(_vm.basicInfo.sysName)))])
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _c(
        "el-menu",
        {
          attrs: {
            "show-timeout": 200,
            "default-active": _vm.activeMenu,
            collapse: _vm.isCollapse,
            "collapse-transition": false,
            "unique-opened": true,
            mode: "vertical",
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF"
          }
        },
        _vm._l(_vm.permission_routers, function(route) {
          return _c("sidebar-item", {
            key: route.path,
            attrs: { item: route, "base-path": route.path }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }