/* eslint-disable */
// import { login, logout, getUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";
import { userInfoCookie, menuListCookie } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    userInfo: {},
    roles: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_ROLES: (state, menu) => {
      state.roles = menu;
    },
    SET_MENULIST: (state, _menuList) => {
      state.menuList = _menuList;
    },
    SET_CUSTOMMENULIST: (state, _customMenuList) => {
      state.customMenuList = _customMenuList;
    },
    SET_USERTYPE: (state, _userType) => {
      state.userType = _userType;
    }
  },

  actions: {
    //  用户名登录
    LoginByUsername({ commit, state }, { userInfo, menuList, customMenuList }) {
      menuList = menuList || [];
      // menuList = menuList.map(item => {
      //   return item.menu_Id;
      // });
      return new Promise((resolve, reject) => {
        commit("SET_USERINFO", userInfo);
        commit("SET_MENULIST", menuList);
        commit("SET_CUSTOMMENULIST", customMenuList);
        userInfoCookie.setUserInfo(userInfo);
        menuListCookie.setMenuList(menuList);
        menuListCookie.setCustomMenuList(customMenuList);
        resolve();
      });
    },

    //  用户类别
    setUserType({ commit, state }, userType) {
      commit("SET_USERTYPE", userType);
    },

    //  获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        var userInfo = userInfoCookie.getUserInfo();
        commit("SET_USERINFO", userInfo);
        var menuList = menuListCookie.getMenuList();
        commit("SET_MENULIST", menuList);
        var customMenuList = menuListCookie.getCustomMenuList();
        commit("SET_CUSTOMMENULIST", customMenuList);

        const menu = [
          // 子应用与菜单
          {
            appName: "第一个子应用",
            appPrefix: "/sub-app1",
            icon: "el-icon-bell",
            // noDisplay: true,  // 是否具有该权限
            // ...  一些其他的信息
            appMenus: []
          },
          {
            appName: "扩展系统",
            appPrefix: "/sub-extend",
            icon: "el-icon-bell",
            // noDisplay: true,  // 是否具有该权限
            // ...  一些其他的信息
            appMenus: []
          },
          {
            appName: "第2个子应用",
            appPrefix: "/sub-app2",
            // noDisplay: true,
            appMenus: [
              {
                menuName: "一级菜单",
                path: "/",
                icon: "el-icon-user",
                children: [
                  {
                    menuName: "二级菜单",
                    path: "/bbb",
                    icon: "el-icon-user"
                  }
                ]
              },
              {
                menuName: "一级菜单",
                path: "/about",
                icon: "el-icon-user"
              }
            ]
          },
          {
            appName: "第三个子应用",
            appPrefix: "/sub-app3",
            // noDisplay: true,
            appMenus: [
              {
                menuName: "一级菜单",
                path: "/",
                icon: "el-icon-user"
              },
              {
                menuName: "一级菜单",
                path: "/about",
                icon: "el-icon-user"
              }
            ]
          },
          {
            appName: "测试重连机制",
            appPrefix: "/sub-app4",
            // noDisplay: true,
            appMenus: [
              {
                menuName: "1111",
                path: "/",
                icon: "el-icon-user"
              },
              {
                menuName: "2222",
                path: "/about",
                icon: "el-icon-user"
              }
            ]
          }
        ];
        resolve({
          userInfo: userInfo,
          menuList: menuList,
          customMenuList: customMenuList,
          menu: menu
        });
      });
    },

    // 动态修改权限
    changeRoles({ dispatch }) {
      return new Promise(async resolve => {
        const { data } = await dispatch("getUserInfo");
        resetRouter();
        const { menu, id } = data.userInfo;
        const accessRoutes = await dispatch("generateRoutes", { id, menu }); // 动态修改权限后 重绘侧边菜单
        // dynamically add accessible routes
        router.addRoutes(accessRoutes);
        resolve();
      });
    },
    // 登出
    logOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        userInfoCookie.removeUserInfo();
        commit("SET_USERINFO", {});
        resolve();
      });
    },

    //  登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        userInfoCookie.removeUserInfo();
        commit("SET_USERINFO", {});
        resolve();
      });
    },
    // 前端 登出
    fedLogOut({ commit }) {
      return new Promise(resolve => {
        sessionStorage.clear(true);
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
